import "./App.css";
import { Home } from "./Home";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter, NavLink } from "react-router-dom";
import { Deckbuilder } from "./Deckbuilder";
import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App .container-fluid colorBG">
        <div className="wide100per colorBGBlack">
          <img
            src="../assets/images/SWLCCGLogo.png"
            className="imgNoRadius h130px"
          ></img>
        </div>

        <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
          <ul className="nav navbar-nav">
            <li className="nav-item- m-1">
              <NavLink className="btn btn-dark btn-outline-secondary" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item- m-1">
              <NavLink
                className="btn btn-dark btn-outline-secondary"
                to="/Deckbuilder"
              >
                Deck Builder
              </NavLink>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Deckbuilder" element={<Deckbuilder />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
