import React from "react";

const cardBack =
  "https://cdn.discordapp.com/attachments/1011437342931816508/1011656722424795206/Cardback_New.jpg";

const CustomDeck = (cardExcel, deckList) => {
  // Array of card IDs
  const cardIDs = deckList.map((item, index) => {
    const name = item.Name;
    return { id: 100 + index * 101, name };
  });

  // Array of nicknames
  const nicknames = deckList.map((card) => card.Name);

  // Create an array to hold the card objects
  const cards = deckList.map((card, index) => ({
    CardID: cardIDs
      .filter((item) => item.name === card.Name)
      .map((item) => item.id)[0],
    Name: "Card",
    Nickname: nicknames[index],
    Transform: {
      posX: 0,
      posY: 0,
      posZ: 0,
      rotX: 0,
      rotY: 180,
      rotZ: 180,
      scaleX: 1,
      scaleY: 1,
      scaleZ: 1,
    },
  }));

  // Define the deck data with the dynamic CustomDeck property
  const deckData = {
    ObjectStates: [
      {
        Name: "DeckCustom",
        ContainedObjects: cards,
        DeckIDs: cardIDs.map((card) => card.id),
        CustomDeck: deckList.reduce((result, card, index) => {
          result[index + 1] = {
            FaceURL: card.ImageFile,
            BackURL: card.BACKURL || cardBack,
            NumHeight: 1,
            NumWidth: 1,
            BackIsHidden: true,
          };
          return result;
        }, {}),
        Transform: {
          posX: 0,
          posY: 1,
          posZ: 0,
          rotX: 0,
          rotY: 180,
          rotZ: 180,
          scaleX: 1,
          scaleY: 1,
          scaleZ: 1,
        },
      },
    ],
  };

  return deckData;
};

export default CustomDeck;
