export const variables = {
  API_URL: "https://SWLCCGDB-backend-test.herokuapp.com/cards",
  PLAYER_API_URL: "https://SWLCCGDB-backend-test.herokuapp.com/players/",
  PHOT_URL: "http://localhost:8005/cards/images/",
  deckcards: [],
  itemcards: [],
  sigcards: [],
  stamcards: [],
  crycards: [],
  deckName: "",
};
