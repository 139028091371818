import React, { Component, component } from "react";

export class Home extends Component {
  render() {
    return (
      <div className="colorBG container">
        <h3 className="colorBGBlack">This is home page.</h3>
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
          <ul className="navbar-nav">
            <li className="nav-item- m-1">
              <a
                className="btn btn-dark btn-outline-secondary"
                href="https://discord.gg/dw8Vjmbhha"
                target="_blank"
              >
                Silver Wing Legacy's Discord
              </a>
            </li>
            <li className="nav-item- m-1">
              <a
                className="btn btn-dark btn-outline-secondary"
                href="https://www.youtube.com/channel/UCde5ziMwkVbXY43_1bWmBLw"
                target="_blank"
              >
                Grie's YouTube
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
