import React, { Component } from "react";
import { variables } from "./Variables.js";
import DeckExport from "./component/DeckExport.js";
import { rawCards } from "./data";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Papa from "papaparse";
import {
  faSquareMinus,
  faSquarePlus,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import ImageComponent from "./component/ImageComponent";

//import { DeckList } from "./DeckList";

export class Deckbuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardList: [],
      firstHand: [],
      cardExcel: [],
      DCards: variables.deckcards,
      ICards: variables.itemcards,
      SCards: variables.sigcards,
      StCards: variables.stamcards,
      CryCards: variables.crycards,
      Colors: [],
      deckDisplay: [],
      itemDisplay: [],
      sigDisplay: [],
      stamDisplay: [],
      cryDisplay: [],
      buttonFilter: "",
      dLevel: "",
      gold: "",
      Name: "",
      cardsWithoutFilter: [],
      PhotoPath: variables.PHOT_URL,
      deckSizeLimit: 70,
    };
  }

  changeNameFilter = (e) => {
    this.state.NameFilter = e.target.value;
    this.FilterFn();
  };

  addCardtoArray = (e) => {
    //rules
    //3 Stamina cards
    //1 Main Character card
    //Up to 1 Signature card
    //2 Crystal cards
    //Minimum 50 card Main Deck containing Ally and Support cards (Maximum of 70)
    //Maximum 1 copy of a Main Character card with the same name and tag
    //Maximum 1 copy of a Signature card with the same name

    //check max size
    if (this.state.DCards.length < this.state.deckSizeLimit) {
      //filteredData will just have the new card
      var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
        return el.UniName?.toString()
          .toLowerCase()
          .includes(e.toString().trim().toLowerCase());
      });
      //If the card is an item, put in item row
      if (filteredData.length > 0) {
        if (filteredData[0].CardType.toString().toLowerCase().includes("mc")) {
          var ItemC = [
            {
              ID: filteredData[0].ID,
              guid: uuid(),
              Name: filteredData[0].Name,
              UniName: filteredData[0].UniName,
              ImageFile: filteredData[0].ImageFile,
              CardType: filteredData[0].CardType,
              Color: filteredData[0].Color,
              ColorType: filteredData[0].ColorType,
              Strategy: filteredData[0].Strategy,
              ArchetypeColorType: filteredData[0].ArchetypeColorType,
              Effect: filteredData[0].Effect,
              BACKURL: filteredData[0].BACKURL,
            },
          ];
          var maxICard = this.state.ICards.filter(function (el) {
            return el.UniName?.toString()
              .toLowerCase()
              .includes(ItemC[0].UniName.toString().trim().toLowerCase());
          });

          var maindeckMC = false;
          if (this.state.DCards.length > 0) {
            maindeckMC = this.state.DCards.filter(function (el) {
              return el.Name?.toString()
                .toLowerCase()
                .includes(ItemC[0].Name.toString().trim().toLowerCase());
            });
          }

          if (
            maxICard.length < 1 &&
            this.state.ICards.length == 0 &&
            maindeckMC == false
          ) {
            this.state.ICards.push(ItemC[0]);

            var newvar2 = this.state.ICards;
            variables.itemcards = newvar2;
            this.setState({ ICards: newvar2 });
            this.sortItemResult("Name");
          }
        } else if (
          filteredData[0].CardType.toString()
            .toLowerCase()
            .includes("signature")
        ) {
          var SigC = [
            {
              ID: filteredData[0].ID,
              guid: uuid(),
              Name: filteredData[0].Name,
              UniName: filteredData[0].UniName,
              ImageFile: filteredData[0].ImageFile,
              CardType: filteredData[0].CardType,
              Color: filteredData[0].Color,
              ColorType: filteredData[0].ColorType,
              Strategy: filteredData[0].Strategy,
              ArchetypeColorType: filteredData[0].ArchetypeColorType,
              Effect: filteredData[0].Effect,
              BACKURL: filteredData[0].BACKURL,
            },
          ];
          var maxSCard = this.state.SCards.filter(function (el) {
            return el.UniName?.toString()
              .toLowerCase()
              .includes(SigC[0].UniName.toString().trim().toLowerCase());
          });
          if (maxSCard.length < 1 && this.state.SCards.length == 0) {
            this.state.SCards.push(SigC[0]);

            var newvar2 = this.state.SCards;
            variables.sigcards = newvar2;
            this.setState({ SCards: newvar2 });
            this.sortItemResult("Name");
          }
        } else if (
          filteredData[0].CardType.toString().toLowerCase().includes("stamina")
        ) {
          var StamC = [
            {
              ID: filteredData[0].ID,
              guid: uuid(),
              Name: filteredData[0].Name,
              UniName: filteredData[0].UniName,
              ImageFile: filteredData[0].ImageFile,
              CardType: filteredData[0].CardType,
              Color: filteredData[0].Color,
              ColorType: filteredData[0].ColorType,
              Strategy: filteredData[0].Strategy,
              ArchetypeColorType: filteredData[0].ArchetypeColorType,
              Effect: filteredData[0].Effect,
              BACKURL: filteredData[0].BACKURL,
            },
          ];
          var maxSCard = this.state.StCards.filter(function (el) {
            return el.UniName?.toString()
              .toLowerCase()
              .includes(StamC[0].UniName.toString().trim().toLowerCase());
          });
          if (maxSCard.length < 3) {
            this.state.StCards.push(StamC[0]);

            var newvar2 = this.state.StCards;
            variables.stamcards = newvar2;
            this.setState({ StCards: newvar2 });
            this.sortItemResult("UniName");
          }
        } else if (
          filteredData[0].CardType.toString().toLowerCase().includes("crystal")
        ) {
          var CryC = [
            {
              ID: filteredData[0].ID,
              guid: uuid(),
              Name: filteredData[0].Name,
              UniName: filteredData[0].UniName,
              ImageFile: filteredData[0].ImageFile,
              CardType: filteredData[0].CardType,
              Color: filteredData[0].Color,
              ColorType: filteredData[0].ColorType,
              Strategy: filteredData[0].Strategy,
              ArchetypeColorType: filteredData[0].ArchetypeColorType,
              Effect: filteredData[0].Effect,
              BACKURL: filteredData[0].BACKURL,
            },
          ];
          var maxSCard = this.state.CryCards.filter(function (el) {
            return el.UniName?.toString()
              .toLowerCase()
              .includes(CryC[0].UniName.toString().trim().toLowerCase());
          });
          if (maxSCard.length < 2) {
            this.state.CryCards.push(CryC[0]);

            var newvar2 = this.state.CryCards;
            variables.crycards = newvar2;
            this.setState({ CryCards: newvar2 });
            this.sortItemResult("UniName");
          }
        } else {
          var DeckC = [
            {
              ID: filteredData[0].ID,
              guid: uuid(),
              Name: filteredData[0].Name,
              UniName: filteredData[0].UniName,
              ImageFile: filteredData[0].ImageFile,
              CardType: filteredData[0].CardType,
              Color: filteredData[0].Color,
              ColorType: filteredData[0].ColorType,
              Strategy: filteredData[0].Strategy,
              ArchetypeColorType: filteredData[0].ArchetypeColorType,
              Effect: filteredData[0].Effect,
              BACKURL: filteredData[0].BACKURL,
            },
          ];

          var maxCard = this.state.DCards.filter(function (el) {
            return el.UniName?.toString()
              .toLowerCase()
              .includes(DeckC[0].UniName.toString().trim().toLowerCase());
          });

          //You can't have an Ally card in your Main Deck with the same name as your Main Character
          var MCpicked = true;
          if (this.state.ICards.length > 0) {
            MCpicked =
              this.state.ICards[0].Name.toString() != DeckC[0].Name.toString();
          }

          //Maximum of 3 total copies of cards with <Protector>
          //</Protector>Maximum of 3 copies of a Support card with the same name
          var proCap = false;
          if (
            DeckC[0].Effect.toString().toLowerCase().startsWith("<protector>")
          ) {
            var protectCap = this.state.DCards.filter(function (el) {
              return el.Effect.toString()
                .toLowerCase()
                .startsWith("<protector>");
            });
            if (protectCap.length === 3) {
              proCap = true;
            }
          }
          //Maximum of 3 copies of Ally cards with the same name, tag, and color
          if (maxCard.length < 3 && MCpicked == true && proCap == false) {
            this.state.DCards.push(DeckC[0]);

            var newvar2 = this.state.DCards;

            variables.deckcards = newvar2;
            this.setState({ DCards: newvar2 });

            document.getElementById("cardCount").innerHTML = parseInt(
              this.state.DCards.length
            );

            this.sortCardResult("CardType");
          }
        }
        this.deckDisplay();
      }
    }
  };

  deckDisplay() {
    //Main Deck
    var deck = variables.deckcards;
    var deckSort = deck.map((Deck) => ({
      Name: Deck.Name,
      UniName: Deck.UniName,
      ImageFile: Deck.ImageFile,
      CardType: Deck.CardType,
      Color: Deck.Color,
      ColorType: Deck.ColorType,
      Strategy: Deck.Strategy,
      ArchetypeColorType: Deck.ArchetypeColorType,
      Count: 0,
    }));

    var deckFiltered = deckSort.filter(
      (v, i, a) => a.findIndex((v2) => v2.UniName === v.UniName) === i
    );

    deckFiltered.forEach(function (i) {
      i.Count = deck.filter(
        (obj) => obj.UniName === i.UniName.toString()
      ).length;
    });
    //Main Character
    var item = variables.itemcards;
    var itemSort = item.map((item) => ({
      Name: item.Name,
      UniName: item.UniName,
      ImageFile: item.ImageFile,
      CardType: item.CardType,
      Color: item.Color,
      ColorType: item.ColorType,
      Strategy: item.Strategy,
      ArchetypeColorType: item.ArchetypeColorType,
      Count: 0,
    }));

    var itemFiltered = itemSort.filter(
      (v, i, a) => a.findIndex((v2) => v2.UniName === v.UniName) === i
    );

    itemFiltered.forEach(function (i) {
      i.Count = item.filter(
        (obj) => obj.UniName === i.UniName.toString()
      ).length;
    });
    //Signature Cards
    var sig = variables.sigcards;
    var sigSort = sig.map((sig) => ({
      Name: sig.Name,
      UniName: sig.UniName,
      ImageFile: sig.ImageFile,
      CardType: sig.CardType,
      Color: sig.Color,
      ColorType: sig.ColorType,
      Strategy: sig.Strategy,
      ArchetypeColorType: sig.ArchetypeColorType,
      Count: 0,
    }));

    var sigFiltered = sigSort.filter(
      (v, i, a) => a.findIndex((v2) => v2.UniName === v.UniName) === i
    );

    sigFiltered.forEach(function (i) {
      i.Count = sig.filter(
        (obj) => obj.UniName === i.UniName.toString()
      ).length;
    });
    //Stamina Cards
    var stam = variables.stamcards;
    var stamSort = stam.map((stam) => ({
      Name: stam.Name,
      UniName: stam.UniName,
      ImageFile: stam.ImageFile,
      CardType: stam.CardType,
      Color: stam.Color,
      ColorType: stam.ColorType,
      Strategy: stam.Strategy,
      ArchetypeColorType: stam.ArchetypeColorType,
      Count: 0,
    }));

    var stamFiltered = stamSort.filter(
      (v, i, a) => a.findIndex((v2) => v2.UniName === v.UniName) === i
    );

    stamFiltered.forEach(function (i) {
      i.Count = stam.filter(
        (obj) => obj.UniName === i.UniName.toString()
      ).length;
    });
    //Crystal Cards
    var cry = variables.crycards;
    var crySort = cry.map((cry) => ({
      Name: cry.Name,
      UniName: cry.UniName,
      ImageFile: cry.ImageFile,
      CardType: cry.CardType,
      Color: cry.Color,
      ColorType: cry.ColorType,
      Strategy: cry.Strategy,
      ArchetypeColorType: cry.ArchetypeColorType,
      Count: 0,
    }));

    var cryFiltered = crySort.filter(
      (v, i, a) => a.findIndex((v2) => v2.UniName === v.UniName) === i
    );

    cryFiltered.forEach(function (i) {
      i.Count = cry.filter(
        (obj) => obj.UniName === i.UniName.toString()
      ).length;
    });

    //this.updateDeckStats();
    this.setState({ deckDisplay: deckFiltered });
    this.setState({ itemDisplay: itemFiltered });
    this.setState({ sigDisplay: sigFiltered });
    this.setState({ stamDisplay: stamFiltered });
    this.setState({ cryDisplay: cryFiltered });
  }

  updateDeckStats() {
    var deckStats = document.getElementById("divDeckStats");
    deckStats.innerHTML = "";

    var deck = variables.deckcards;
    var deckLength = deck.length;
    //Number of Attacks
    var attackCount = deck.reduce(
      (acc, cur) => (cur.Strategy.includes("Attack") ? ++acc : acc),
      0
    );
    var blockCount = deck.reduce(
      (acc, cur) => (cur.Strategy.includes("Block") ? ++acc : acc),
      0
    );
    var otherCount = deck.reduce(
      (acc, cur) =>
        !cur.Strategy.includes("Block") & !cur.Strategy.includes("Attack")
          ? ++acc
          : acc,
      0
    );
    var powerAvg = 0;
    var focusAvg = 0;
    if (attackCount) {
      deckStats.innerHTML = "Attack Action Count: " + attackCount;
    }
    //Number of Blocks
    if (blockCount) {
      deckStats.innerHTML += "<br/> Block Action Count: " + blockCount;
    }
    //Average AP Cost
    if (otherCount) {
      deckStats.innerHTML += "<br/> Other Action Count: " + otherCount;
    }

    //Average Power
    var filteredData = deck.filter((e) => e.Power != "");
    powerAvg =
      filteredData.reduce(
        (r, c) => r + parseInt(c.Power.toString().replace("*", 0)),
        0
      ) / filteredData.length;
    if (powerAvg) {
      deckStats.innerHTML +=
        "<br/> Average Power: " + powerAvg.toString().substring(0, 4);
    }

    //Average Focus
    var filteredData2 = deck.filter((e) => e.Focus != "");
    focusAvg =
      filteredData2.reduce(
        (r, c) => r + parseInt(c.Focus.toString().replace("*", 0)),
        0
      ) / filteredData.length;
    if (focusAvg) {
      deckStats.innerHTML +=
        "<br/> Average Focus: " + focusAvg.toString().substring(0, 4);
    }
  }

  calcDeckStats() {
    var deck = variables.deckcards;
    document.getElementById("cardCount").innerHTML = deck.length;
  }

  removeCardfromArray = (e) => {
    var deckFilterArray = this.state.DCards.filter(function (del) {
      return del.UniName === e;
    });

    var removedCard = deckFilterArray[0].guid.toString();

    deckFilterArray = this.state.DCards.filter(function (del) {
      return del.guid !== removedCard;
    });

    //this.setState({DCards:deckFilterArray})
    variables.deckcards = [...deckFilterArray];
    this.state.DCards = deckFilterArray;
    document.getElementById("cardCount").innerHTML = this.state.DCards.length;
    this.deckDisplay();
  };

  removeItemfromArray = (e) => {
    var deckFilterArray = this.state.ICards.filter(function (del) {
      return del.UniName === e;
    });

    var removedCard = deckFilterArray[0].guid.toString();

    deckFilterArray = this.state.ICards.filter(function (del) {
      return del.guid !== removedCard;
    });

    variables.itemcards = [...deckFilterArray];
    this.deckDisplay();
    this.setState({ ICards: deckFilterArray });
  };

  removeSigfromArray = (e) => {
    var deckFilterArray = this.state.SCards.filter(function (del) {
      return del.UniName === e;
    });

    var removedCard = deckFilterArray[0].guid.toString();

    deckFilterArray = this.state.SCards.filter(function (del) {
      return del.guid !== removedCard;
    });

    variables.sigcards = [...deckFilterArray];
    this.deckDisplay();
    this.setState({ SCards: deckFilterArray });
  };

  removeStamfromArray = (e) => {
    var deckFilterArray = this.state.StCards.filter(function (del) {
      return del.UniName === e;
    });

    var removedCard = deckFilterArray[0].guid.toString();

    deckFilterArray = this.state.StCards.filter(function (del) {
      return del.guid !== removedCard;
    });

    variables.stamcards = [...deckFilterArray];
    this.deckDisplay();
    this.setState({ StCards: deckFilterArray });
  };

  removeCryfromArray = (e) => {
    var deckFilterArray = this.state.CryCards.filter(function (del) {
      return del.UniName === e;
    });

    var removedCard = deckFilterArray[0].guid.toString();

    deckFilterArray = this.state.CryCards.filter(function (del) {
      return del.guid !== removedCard;
    });

    variables.crycards = [...deckFilterArray];
    this.deckDisplay();
    this.setState({ CryCards: deckFilterArray });
  };

  sortItemResult(prop) {
    var sortData = this.state.ICards;

    var sortedData = sortData.sort(function (a, b) {
      return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
    });

    this.setState({ ICards: sortedData });
  }

  sortCardResult(prop) {
    var sortData = this.state.DCards;

    var sortedData = sortData.sort(function (a, b) {
      return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
    });

    this.setState({ DCards: sortedData });
  }

  sortResult(prop, asc) {
    var sortData = this.state.deckDisplay;

    var sortedData = sortData.sort(function (a, b) {
      if (asc) {
        return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
      } else {
        return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
      }
    });

    this.setState({ deckDisplay: sortedData });
  }

  refreshList() {
    this.state.buttonFilter = "";
    this.state.NameFilter = "";
    this.fetchGoogleSheetData();
    //this.setState({ cardList: rawCards, cardsWithoutFilter: rawCards });
  }

  FilterFn() {
    var NameFilter = this.state.NameFilter;
    var filterArray = NameFilter.split(",");

    if (NameFilter.toString().includes(",")) {
      var multiFilterArray = [];

      filterArray.forEach((i, indx) => {
        if (indx === 0) {
          if (i.toString().toLowerCase().includes("tag:")) {
            i = i.replace("tag:", "");
            //Tag
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.Tag?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          } else if (i.toString().toLowerCase().includes("cardt:")) {
            //Card Type
            i = i.replace("cardt:", "");
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.CardType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          } else if (i.toString().toLowerCase().includes("arch:")) {
            //Archtype Color Type
            i = i.replace("arch:", "");
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.ArchetypeColorType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          } else if (i.toString().toLowerCase().includes("color:")) {
            //Color
            i = i.replace("color:", "");
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.Color?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          } else if (i.toString().toLowerCase().includes("colort:")) {
            //Color Type
            i = i.replace("colort:", "");
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.ColorType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          } else if (i.toString().toLowerCase().includes("strat:")) {
            //Strategy
            i = i.replace("strat:", "");
            var filteredData = this.state.cardsWithoutFilter.filter(function (
              el
            ) {
              return el.Strategy?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = multiFilterArray.concat(filteredData);
          }
        } else {
          if (i.toString().toLowerCase().includes("tag:")) {
            i = i.replace("tag:", "");
            //Color
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.Tag?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          } else if (i.toString().toLowerCase().includes("cardt:")) {
            //Color requirement
            i = i.replace("cardt:", "");
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.CardType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          } else if (i.toString().toLowerCase().includes("arch:")) {
            //Archetype Color Type
            i = i.replace("arch:", "");
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.ArchetypeColorType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          } else if (i.toString().toLowerCase().includes("color:")) {
            //Color
            i = i.replace("color:", "");
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.Color?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          } else if (i.toString().toLowerCase().includes("colort:")) {
            //Color Type
            i = i.replace("colort:", "");
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.ColorType?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          } else if (i.toString().toLowerCase().includes("strat:")) {
            //Strategy
            i = i.replace("strat:", "");
            var filteredData2 = multiFilterArray.filter(function (el) {
              return el.Strategy?.toString()
                .toLowerCase()
                .includes(i.toString().trim().toLowerCase());
            });
            multiFilterArray = filteredData2;
          }
        }
      });
      this.setState({ cardList: multiFilterArray });
    } else {
      if (NameFilter.toString().toLowerCase().includes("tag:")) {
        NameFilter = NameFilter.replace("tag:", "");
        //Tag
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.Tag?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
      } else if (NameFilter.toString().toLowerCase().includes("cardt:")) {
        //Card Type
        NameFilter = NameFilter.replace("cardt:", "");
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.CardType?.toString()
            .toLowerCase()
            .startsWith(NameFilter.toString().trim().toLowerCase());
        });
      } else if (NameFilter.toString().toLowerCase().includes("arch:")) {
        //Archtype Color Type
        NameFilter = NameFilter.replace("arch:", "");
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.ArchetypeColorType?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
      } else if (NameFilter.toString().toLowerCase().includes("color:")) {
        //Color
        NameFilter = NameFilter.replace("color:", "");
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.Color?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
      } else if (NameFilter.toString().toLowerCase().includes("colort:")) {
        //Color Type
        NameFilter = NameFilter.replace("colort:", "");
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.ColorType?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
      } else if (NameFilter.toString().toLowerCase().includes("strat:")) {
        //Strategy
        NameFilter = NameFilter.replace("strat:", "");
        var filteredData = this.state.cardsWithoutFilter.filter(function (el) {
          return el.Strategy?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
      } else {
        //Default Name/Effect
        var filteredData1 = this.state.cardsWithoutFilter.filter(function (el) {
          return el.Name?.toString()
            .toLowerCase()
            .includes(NameFilter.toString().trim().toLowerCase());
        });
        var filteredData2 = this.state.cardsWithoutFilter.filter(function (el) {
          return (
            el.Effect?.toString()
              .toLowerCase()
              .includes(NameFilter.toString().trim().toLowerCase()) &&
            !el.Name?.toString()
              .toLowerCase()
              .includes(NameFilter.toString().trim().toLowerCase())
          );
        });
        var filteredData = filteredData1.concat(filteredData2);
      }
      this.setState({ cardList: filteredData });
    }
  }

  componentDidMount() {
    this.refreshList();
    //var dname = variables.deckName;
    //document.getElementById("dName").value = dname;

    if (variables.itemcards.length) {
      this.calcDeckStats();
    }
    if (variables.deckcards.length) {
      //this.updateDeckStatsLevel();
      //this.updateDeckStats();
    }
  }

  fetchGoogleSheetData() {
    Papa.parse(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSEbrCHJZFl1mcpcZcygxt-Xmz-p00UF9VDCgtz8tVKz6B6QNtD33WWy5hVVjD94A/pub?gid=483267866&single=true&output=csv",
      {
        download: true,
        header: true,
        complete: (results) => {
          this.setState({
            cardExcel: results.data,
            cardList: results.data,
            cardsWithoutFilter: results.data,
          });
        },
      }
    );
  }

  exportDeck() {
    var list = "";
    //Main Deck
    var deck = variables.deckcards;
    var deckSort = [...new Set(deck.map((item) => item.UniName))];
    var deckCardCount = [];
    //Main character
    var items = variables.itemcards;
    var itemsSort = [...new Set(items.map((item) => item.UniName))];
    var itemCardCount = [];
    //Signature
    var sig = variables.sigcards;
    var sigSort = [...new Set(sig.map((sig) => sig.UniName))];
    var sigCardCount = [];
    //Stamina
    var stams = variables.stamcards;
    var stamsSort = [...new Set(stams.map((stam) => stam.Name))];
    var stamsCardCount = [];
    //Crystals
    var cry = variables.crycards;
    var crySort = [...new Set(cry.map((cry) => cry.Name))];
    var cryCardCount = [];

    list += "Deck Info";

    //Main Character

    list += "\r\n\r\nMain Character\r\n";
    var encodeString = "";
    itemsSort.forEach(function (i) {
      itemCardCount.push([
        i,
        items.filter((obj) => obj.UniName === i.toString()).length,
      ]);
      encodeString += i;
      list += "\r\n" + i;
    });

    //Signature Card
    list += "\r\n\r\nSignature Card\r\n";

    sigSort.forEach(function (i) {
      sigCardCount.push([
        i,
        sig.filter((obj) => obj.UniName === i.toString()).length,
      ]);
      encodeString += "\r\n" + i;
      list += "\r\n" + i;
    });

    //Stamina

    stamsSort.forEach(function (i) {
      stamsCardCount.push([
        i,
        stams.filter((obj) => obj.Name === i.toString()).length,
      ]);
      list += "\r\n" + i;
      list += " - " + stams.filter((obj) => obj.Name === i.toString()).length;
    });

    //Crystals

    crySort.forEach(function (i) {
      cryCardCount.push([
        i,
        cry.filter((obj) => obj.Name === i.toString()).length,
      ]);
      list += "\r\n" + i;
      list += " - " + cry.filter((obj) => obj.Name === i.toString()).length;
    });

    //Main Deck Cards
    list += "\r\n\r\nMaindeck Cards\r\n";

    deckSort.forEach(function (i) {
      deckCardCount.push([
        i,
        deck.filter((obj) => obj.UniName === i.toString()).length,
      ]);
      encodeString += "\r\n" + i;
      encodeString +=
        " ^ " + deck.filter((obj) => obj.UniName === i.toString()).length;
      list += "\r\n" + i;
      list += " - " + deck.filter((obj) => obj.UniName === i.toString()).length;
    });
    encodeString = btoa(encodeString);

    list += "\r\n\r\n\r\nGenerated by the SWLCCG - Thanks for using! - Grie";
    list = list + "\r\n" + "Deck List String: " + "\r\n" + encodeString;
    var modal = document.getElementById("myModal");

    var span = document.getElementById("modalText");
    modal.style.display = "block";
    span.value = list;
  }

  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  importDeck() {
    this.state.ICards = [];
    this.state.DCards = [];
    this.state.SCards = [];
    this.state.StCards = [];
    this.state.CryCards = [];
    variables.itemcards = [];
    variables.deckcards = [];
    variables.sigcards = [];
    variables.stamcards = [];
    variables.crycards = [];

    var unencodeString = document
      .getElementById("importText")
      .value.replaceAll("’", "'");

    var textDeckList = atob(unencodeString);
    var lines = textDeckList.split("\n");

    //assests

    for (let i = 0; i < lines.length; i++) {
      if (lines[i]) {
        var lName = lines[i].toString().toLowerCase();
        var item = "";
        var itemName = lName;
        var itemCount = 1;
        if (
          lName.toString().includes("(w)") ||
          lName.toString().includes("(byr)")
        ) {
          item = lines[i]
            .toString()
            .toLowerCase()
            .replace("(w)", "")
            .replace("(byr)", "");
          itemName = item.toString().trim().replaceAll("\r", "");
          itemCount = 1;
        } else {
          item = lines[i].toString().toLowerCase().split(" ^ ");
          itemName = item[0].toString().trim().replaceAll("\r", "");
          itemCount = item[1];
        }

        for (let i = 0; i < itemCount; i++) {
          this.addCardtoArray(itemName.toString());
        }
      }
    }
    this.addCardtoArray("Stamina! - (-)");
    this.addCardtoArray("Stamina! - (-)");
    this.addCardtoArray("Stamina! - (-)");
    this.addCardtoArray("Crystal - (-)");
    this.addCardtoArray("Crystal - (-)");

    var modal = document.getElementById("importDeck");
    var span = document.getElementById("importText");
    modal.style.display = "none";
  }

  drawHand() {
    this.setState({ firstHand: [] });
    var deck = this.state.DCards;
    var firsthan = [];

    deck.forEach(function (card, inc) {
      firsthan.push({ key: uuid(), ImageFile: deck[inc].ImageFile });
    });

    this.shuffle(firsthan);

    firsthan.length = 5;

    this.setState({ firstHand: firsthan });
    this.sortCardResult(document.getElementById("sortSelect").value);
  }

  closeModal() {
    var modal = document.getElementById("myModal");
    var modal2 = document.getElementById("myModal2");
    var modal3 = document.getElementById("importDeck");
    var modal4 = document.getElementById("testHand");
    this.setState({ firstHand: [] });
    modal.style.display = "none";
    modal2.style.display = "none";
    modal3.style.display = "none";
    modal4.style.display = "none";
  }

  showTips() {
    var modal = document.getElementById("myModal2");

    modal.style.display = "block";
  }

  hideItems() {
    var ddisplay = document.getElementById("divItemDisplay");

    ddisplay.classList.toggle("hide");
  }

  hideDeck() {
    var ddisplay = document.getElementById("divDeckDisplay");

    ddisplay.classList.toggle("hide");
  }

  copyText() {
    /* Get the text field */
    var copyText = document.getElementById("modalText");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    //alert("Copied the text: " + copyText.value);
  }

  showImport() {
    var modal3 = document.getElementById("importDeck");

    modal3.style.display = "block";
  }

  showTestHand() {
    var modal3 = document.getElementById("testHand");

    modal3.style.display = "block";
  }

  saveDeckname(e) {
    variables.deckName = e.target.value;
  }

  handleChangeSort = (event) => {
    this.sortCardResult(event.target.value);
  };

  handleChangeASC = () => {
    this.sortResult(document.getElementById("sortSelect").value, true);
  };

  handleChangeDESC = () => {
    this.sortResult(document.getElementById("sortSelect").value, false);
  };

  showDeckStats() {
    var ddisplay = document.getElementById("deckInfoDisplay");

    ddisplay.classList.toggle("hide");
  }

  saveDeck() {
    var item = variables.itemcards;
    var stam = variables.stamcards;
    var sig = variables.sigcards;
    var crys = variables.crycards;
    var deck = variables.deckcards;

    var deckName = document.getElementById("dName").value || "swldeck";
    var deckData = DeckExport(this.state.cardExcel, [
      ...item,
      ...stam,
      ...sig,
      ...crys,
      ...deck,
    ]);

    const deckDataJson = JSON.stringify(deckData, null, 2);
    const blob = new Blob([deckDataJson], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = deckName + ".json";
    link.click();
    URL.revokeObjectURL(url);

    console.log(deckData);
  }

  render() {
    const { cardList, cardExcel, firstHand } = this.state;

    return (
      <>
        <div className="flex-container wide100per">
          <div id="dList" className="flex-container wide25per">
            <div className="flex-container wide100per ">
              <div
                className="flex-container card wide100per navbar navbar-expand-sm bg-dark navbar-dark"
                id="start"
              >
                <div className="pl5px pt10px wide100per">
                  <div className="gridRow2 gridCol1">
                    <input
                      id="dName"
                      placeholder="Deck Name..."
                      className="wide100per"
                      type="text"
                      onChange={this.saveDeckname}
                    ></input>
                  </div>
                  <div className="cardDock mt5px pt5px pb5px">
                    <div className="gridRow1 posRel gridCol1">
                      <span className="posAbso deckCountStyle fs22pt pt12px">
                        <span
                          className="deckCountStyle fs22pt pt12px pr3px"
                          id="cardCount"
                        >
                          0
                        </span>
                      </span>
                      <img
                        src="./assets/images/Symbols/DeckCountermid.png"
                        className="imgNoRadius"
                      />
                    </div>
                  </div>
                  <label>
                    <button
                      className="btn-sm removeBtn"
                      onClick={() => this.showDeckStats()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </button>
                  </label>
                </div>
                <div className="pl5px pt10px  wide100per">
                  <div id="deckInfoDisplay" className="card-body hide">
                    <div className="gridRow1 gridCol12 wide100per pb5px pt10px">
                      <h5 className="card-title">Deck Information </h5>
                    </div>
                    <div className="gridRow2 gridCol1 wide100per pb5px pt10px">
                      <div id="divDeckStats"></div>
                    </div>
                    <div className="gridRow4 wide100per pb5px pt10px">
                      <input
                        type="button"
                        className="card-link"
                        value="Export"
                        id="myBtn"
                        onClick={() => this.exportDeck()}
                      />
                      <input
                        type="button"
                        className="card-link"
                        value="Import"
                        id="myBtn2"
                        onClick={() => this.showImport()}
                      />
                      <input
                        type="button"
                        className="card-link"
                        value="Test Hand"
                        id="myBtn3"
                        onClick={() => this.showTestHand()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="h65vh overflwScroll hFC wide100per">
                <div className="borderBottomGray pb5px wide100per">
                  <div id="divItemDisplay">
                    {" "}
                    Main Character
                    <br />
                    {
                      //MC
                      this.state.itemDisplay.map((ic) => (
                        <div className="cardDock" key={ic.ImageFile}>
                          <div className="textRight gridRow1 gridColumn12">
                            <ImageComponent
                              imgClass="wide211px partialCard"
                              imgURL={ic.ImageFile}
                            ></ImageComponent>
                            <br />
                          </div>
                          <div className="gridRow1 gridColumn3">
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() =>
                                this.removeItemfromArray(ic.UniName)
                              }
                            >
                              <FontAwesomeIcon icon={faSquareMinus} />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div id="divSigDisplay">
                    {" "}
                    Signature Card
                    <br />
                    {
                      //Sigs
                      this.state.sigDisplay.map((sg) => (
                        <div className="cardDock" key={sg.ImageFile}>
                          <div className="textRight gridRow1 gridColumn12">
                            <ImageComponent
                              imgClass="wide211px partialCard"
                              imgURL={sg.ImageFile}
                            ></ImageComponent>
                            <br />
                          </div>
                          <div className="gridRow1 gridColumn3">
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() =>
                                this.removeSigfromArray(sg.UniName)
                              }
                            >
                              <FontAwesomeIcon icon={faSquareMinus} />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div id="divStamDisplay">
                    {" "}
                    Stamina
                    <br />
                    {
                      //Stams
                      this.state.stamDisplay.map((st) => (
                        <div className="cardDock" key={st.ImageFile}>
                          <div className="textRight gridRow1 gridColumn12">
                            <ImageComponent
                              imgClass={"wide211px partialCard " + st.CardType}
                              imgURL={st.ImageFile}
                            ></ImageComponent>
                            <br />
                          </div>
                          <div className="gridRow1 gridColumn3">
                            Count: {st.Count}
                            <br />
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() =>
                                this.removeStamfromArray(st.UniName)
                              }
                            >
                              <FontAwesomeIcon icon={faSquareMinus} />
                            </button>
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() => this.addCardtoArray(st.UniName)}
                            >
                              <FontAwesomeIcon icon={faSquarePlus} />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div id="divStamDisplay">
                    {" "}
                    Crystals
                    <br />
                    {
                      //Crys
                      this.state.cryDisplay.map((cy) => (
                        <div className="cardDock" key={cy.ImageFile}>
                          <div className="textRight gridRow1 gridColumn12">
                            <ImageComponent
                              imgClass={"wide211px partialCard " + cy.CardType}
                              imgURL={cy.ImageFile}
                            ></ImageComponent>
                            <br />
                          </div>
                          <div className="gridRow1 gridColumn3">
                            Count: {cy.Count}
                            <br />
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() =>
                                this.removeCryfromArray(cy.UniName)
                              }
                            >
                              <FontAwesomeIcon icon={faSquareMinus} />
                            </button>
                            <button
                              className="btn btn-dark btn-outline-secondary"
                              onClick={() => this.addCardtoArray(cy.UniName)}
                            >
                              <FontAwesomeIcon icon={faSquarePlus} />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div id="divDeckDisplay">
                  {
                    //Deck
                    this.state.deckDisplay.map((dc) => (
                      <div className="cardDock" key={dc.ImageFile}>
                        <div className="textRight gridRow1 gridColumn12">
                          <ImageComponent
                            imgClass={"wide211px partialCard " + dc.CardType}
                            imgURL={dc.ImageFile}
                          ></ImageComponent>
                        </div>
                        <div className="gridRow1 gridColumn3">
                          Count: {dc.Count}
                          <br />
                          <button
                            className="btn btn-dark btn-outline-secondary"
                            onClick={() => this.removeCardfromArray(dc.UniName)}
                          >
                            <FontAwesomeIcon icon={faSquareMinus} />
                          </button>
                          <button
                            className="btn btn-dark btn-outline-secondary"
                            onClick={() => this.addCardtoArray(dc.UniName)}
                          >
                            <FontAwesomeIcon icon={faSquarePlus} />
                          </button>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            id="cList"
            className="flex-container wide75per pl10px floatRight"
          >
            <div className="flex-container wide100per floatRight h92vh overflwScroll">
              <div className="posAbosolutez10 colorBG wide73per pt10px">
                <input
                  className="btn btn-dark btn-outline-secondary"
                  value={this.state.NameFilter || ""}
                  onChange={this.changeNameFilter}
                />
                <button
                  type="button"
                  className="btn btn-dark btn-outline-secondary"
                  onClick={() => this.refreshList()}
                >
                  Reset
                </button>
                <input
                  className="btn btn-dark btn-outline-secondary"
                  type="button"
                  value="Filter tips"
                  id="myBtn2"
                  onClick={() => this.showTips()}
                />
              </div>
              <div className="flex-container pt70px pl-10">
                {cardList.map((dep) => (
                  <div className="wide14rem h365px" key={dep.ImageFile}>
                    <div>
                      {dep.Name}
                      <br />
                      <ImageComponent
                        imgClass="wide211px h295px"
                        imgURL={dep.ImageFile}
                      ></ImageComponent>
                    </div>
                    <input
                      type="button"
                      value="Add 1 Copy"
                      className="btn btn-dark btn-outline-secondary"
                      onClick={() => this.addCardtoArray(dep?.UniName)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => this.closeModal()}>
              &times;
            </span>
            <textarea id="modalText" className="h365px"></textarea>
            <button onClick={() => this.copyText()}>Copy text</button>
            <button onClick={() => this.saveDeck()}>Export to TTS</button>
          </div>
        </div>
        <div id="myModal2" className="modal">
          <div className="modal-content mwide350">
            <span className="close" onClick={() => this.closeModal()}>
              &times;
            </span>
            <span id="modalText2" className="textLeft">
              The filters use a character and a semi-colon to create more
              complex search results. You can use multiple filters at once, just
              seperate them with commas (Tag:Chill,ColorT:Mono)
              <br />
              <br />
              Tag: Will filter for Tags: Chill, Gazing, Tech Wiz, Dashing, ect
              <br />
              (Tag:Chill)
              <br />
              <br />
              CardT: Will filter for Card Types: Ally, MC, Support, Signature,
              Crystal, Stamina
              <br />
              (CardT:Ally)
              <br />
              <br />
              Arch: Will filter for Archtypes: Hero, Villian, Response,
              Battlefield, ect
              <br />
              (Arch:Response)
              <br />
              <br />
              Color: Will filter for Colors, N will search for cards without a
              color, T will search for Tri Colors: Colors are found with just
              their first letter
              <br />
              (Color:B)
              <br />
              <br />
              ColorT: Will filter for Color Types: -, Mono, Tri, Dual (Color:B)
              <br />
              (ColorT:Mono)
              <br />
              <br />
              Strat: Will filter for Strategy types: Generic, Hero, Villian,
              Offensive, Defensive, ect
              <br />
              (Strat:Hero)
              <br />
              <br />
            </span>
          </div>
        </div>
        <div id="importDeck" className="modal">
          <div className="modal-content wide30vwimp">
            <span className="close" onClick={() => this.closeModal()}>
              &times;
            </span>
            <textarea id="importText"></textarea>
            <button onClick={() => this.importDeck()}>import Deck</button>
          </div>
        </div>
        <div id="testHand" className="modal">
          <div className="modal-content wide80perImp">
            <span className="close" onClick={() => this.closeModal()}>
              &times;
            </span>
            <div className="flex-container">
              {firstHand.map((fhan) => (
                <div key={fhan.key}>
                  <div>
                    <ImageComponent
                      imgClass="wide211px h295px"
                      imgURL={fhan.ImageFile}
                    ></ImageComponent>
                  </div>
                </div>
              ))}
            </div>
            <button className="mwide350" onClick={() => this.drawHand()}>
              Draw Hand
            </button>
          </div>
        </div>
      </>
    );
  }
}
